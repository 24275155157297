import React, { useState, useEffect } from "react"
import { Loader, BankDetails } from '@reactiveonline/frontend_shared_components'
import ErrorMessage from "../checkouts/helpers/ErrorMessage"
import CarrierTrackingInfo from "./CarrierTrackingInfo"
import Cart from "../checkouts/Cart"

export default function TrackOrder({
  appProps, trackOrdersPath, isAccountScreen
}) {
  let url           = new URL(window.location.href)
  let user_email    = url.searchParams.get('user_email')
  let order_number  = url.searchParams.get('order_number')

  const [email, setEmail]               = useState(user_email || appProps.currentUserEmail || '')
  const [orderNo, setOrderNo]           = useState(order_number || '')
  const [order, setOrder]               = useState(undefined)
  const [orderFound, setOrderFound]     = useState(undefined)
  const [orderDetails, setOrderDetails] = useState(null)
  const [loading, setLoading]           = useState(false)

  const trackOrderScreenStyles = {
    minHeight: '50vh',
    marginTop: 50
  }

  useEffect( ()=> {
    if (email.length > 0 && orderNo.length > 0) {
      handleClick()
    }
  },[])

  function handleClick(){
    document.querySelectorAll('[id$="-error-message"]').forEach(errorElement => errorElement.remove())
    document.querySelectorAll('input').forEach(element => element.style.borderColor = '')

    if( !validateEmail()){
      ErrorMessage("email", "red", "email-error-message", `${appProps.translations.checkout.enter_correct_email}`, "red")
    }

    if(orderNo.length === 0){
      ErrorMessage("order-number", "red", "order-number-error-message", `${appProps.translations.orders.order_number_not_filled}`, "red")
    }

    if (validateEmail() && orderNo.length) fetchOrder()
  }

  function validateEmail(){
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
    return re.test(email)
  }

  function fetchOrder(){
    setLoading(true)
    Rails.ajax({
      type: "GET",
      url: `/orders/track?email=${email}&orderNumber=${orderNo}`,
      dataType: "json",
      success: res => {
        setOrderDetails(res.order)
        setOrderFound(true)
        setLoading(false)
      },
      error: res =>{
        setOrderFound(false)
        setOrderDetails(null)
        setLoading(false)
      }
    })
  }

  if(orderFound) return (
    <div className={`${isAccountScreen ? '' : 'main-container'}`}>
      <div className='track-order-wrapper animated fadeIn' style={ !isAccountScreen ? trackOrderScreenStyles : {} }>
        <div className='track-order-container card'>
          { !isAccountScreen &&
            <div className='flex-box flex-wrap content-space-between'>
              <h1>{ appProps.translations.orders.track_order_title }</h1>
              <div className="track-button-wrapper">
                <div className="button" onClick={ ()=> { window.location.href = trackOrdersPath }}>
                  { appProps.translations.transactional_emails.track_order }
                </div>
              </div>
            </div>
          }

          <div className="track-order-table">
            <ul className="headers">
              <li>
                {appProps.translations.orders.order_number}
              </li>
              <li>
                {appProps.translations.transactional_emails.date_ordered}
              </li>
              <li>
                {appProps.translations.orders.order_status}
              </li>
              <li>
                {appProps.translations.orders.shipment_status}
              </li>
              <li>
                {appProps.translations.orders.tracking_number}
              </li>
            </ul>
            <ul className="track-contents">
              <li>
                { orderDetails.number }
              </li>
              <li>
                { orderDetails.completed_at }
              </li>
              <li>
                { appProps.translations.orders[`status_${orderDetails.status}`] }
              </li>
              <li>
                { appProps.translations.orders[`status_${orderDetails.shipmentStatus}`] }
              </li>
              <li>
                { orderDetails.trackingNumber }
              </li>
            </ul>
          </div>
        </div>
      </div>

      { !!orderDetails.bankDetails.length &&
        <div className='card'>
          <BankDetails
            appProps={ appProps }
            bankDetails={ orderDetails.bankDetails }
          />
        </div>
      }

      {/* email.length > 0 && orderNo.length > 0 &&
        <CarrierTrackingInfo
          appProps={ appProps }
          orderNo={ orderNo }
        />
      */}

      <div className='cart-wrapper'>
        <Cart
          appProps                = { appProps }
          products                = { orderDetails.lineItems }
          shippingCost            = { orderDetails.shippingCost }
          paymentCost             = { null }
          subTotal                = { orderDetails.subTotal }
          tax                     = { orderDetails.tax }
          total                   = { orderDetails.total }
          weight                  = { orderDetails.weight }
          removeFromCart          = { false }
          updateLineItemQuantity  = { false }
          cartEditable            = { false }
          loading                 = { false }
          currency                = { appProps.currency }
        />
      </div>
    </div>
  )

  return (
    <div className={`${isAccountScreen ? '' : 'main-container'}`}>
      <div className="track-order-wrapper" style={ !isAccountScreen ? trackOrderScreenStyles : {} }>
        <div className='track-order-container card'>
          { !isAccountScreen && <h1>{ appProps.translations.orders.track_order_title }</h1> }
          <p>
            { appProps.translations.orders.track_order }
          </p>
          <div className="fields">
            <div className="field">
              <input  id="email" type="text" value={ email }
                      name="email"
                      placeholder="Email"
                      onChange={ ( event ) => {
                        setEmail( event.target.value )
                      }}/>
            </div>
            <div className="field">
              <input  id="order-number" type="text" value={orderNo }
                      name="order number"
                      placeholder={ appProps.translations.orders.order_number }
                      onChange={ ( event ) => {
                        setOrderNo(event.target.value)
                      }}/>
            </div>
            <div className="field button-wrapper" style={{ position: 'relative' }}>
              { loading ?
                <Loader
                  absolute
                  size={ 'large' }
                  position={ 'center' }
                />
                :
                <div className="button" onClick={()=> { handleClick() } } >
                  { appProps.translations.transactional_emails.track_order }
                </div>
              }
            </div>
          </div>
          { orderFound !== undefined && appProps.translations.orders.order_not_found }
        </div>
      </div>
    </div>
  )
}
