import React from 'react'

export default function Modal({
  visible, closeModal, styles, mode, icon, children, elementsClass, abortText,
  abortAction, saveText, acceptAction, theme
}) {

  return (
    <>
      { visible &&
        <div className='modal-wrapper flex-box content-center'>
          <div className='modal-overlay' onClick={ closeModal }/>
          <div className={ `modal-content flex-box items-start flex-column ${ mode || '' }` }>
            { closeModal &&
              <div className={ `modal-close ${ theme || '' }` }>
                <div className={ `close_icon flex-box items-center content-center ${ theme || '' }` } onClick={ closeModal }></div>
              </div>
            }
            <div className={`modal-body flex-box flex-column ${ elementsClass || '' } ${ theme || '' }`} style={ styles || {} }>
              { icon &&
                <div className='flex-box items-center content-center'>
                  <div className={`${icon}_icon icon`}></div>
                </div>
              }
              { children }
            </div>
            <div className="modal-actions flex-box items-center content-end">
              { abortAction &&
                <div className="button inverted" onClick={ abortAction }>
                  { abortText }
                </div>
              }
              { acceptAction &&
                <div className="button" onClick={ acceptAction }>
                  { saveText }
                </div>
              }
            </div>
          </div>
        </div>
      }
    </>
  )
}
