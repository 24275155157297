import React from "react"
import ErrorMessage from '../helpers/ErrorMessage'
import { checkoutEmailAnalytics } from '../../../helpers/analytics'
import { checkoutEmailLegacyAnalytics } from '../../../helpers/legacy_analytics'

export default class EmailStep extends React.Component {

  validateEmail(email){
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
    return re.test(email)
  }

  handleClick(){
    const { email, acceptedTerms, proceedToNextStep, googleAnalyticsId, appProps } = this.props

    //Remove error messages from previous click
    document.querySelectorAll('[id$="-error-message"]').forEach(errorElement => errorElement.remove())
    document.querySelectorAll('input').forEach(element => element.style.borderColor = '')
    document.getElementById("term-text").style.color = ''


    if( !this.validateEmail(email) ){
      ErrorMessage("email", "red", "email-error-message", `${appProps.translations.checkout.enter_correct_email}`, "red")
    }

    if( !acceptedTerms ){
      var switchText = document.getElementById("term-text")
      switchText.style.color = 'red'

      let errorMessage = document.createElement("div")
      errorMessage.id = "accept-terms-error-message"
      errorMessage.innerHTML = `${appProps.translations.checkout.user_accept_terms}`
      errorMessage.style.color = "red"

      switchText.parentNode.insertBefore(errorMessage, switchText.nextSibling)
    }

    if (this.validateEmail(email) && acceptedTerms) proceedToNextStep(), appProps.googleMeasurementId ? checkoutEmailAnalytics() : checkoutEmailLegacyAnalytics()
  }

  render(){
    const { email, acceptedTerms, appProps } = this.props

    return(
      <div className="checkout-step email card">
        <div className='step-subtitle'>
          { appProps.translations.checkout.order_email_description }
        </div>

        <div className="fields">
          <div className="field">
            <input
              id="email"
              type="email"
              placeholder="Email"
              value={ email }
              disabled={ !!appProps.user && !!appProps.currentUserEmail }
              onChange ={ (e) => this.props.updateStep("emailProps", "email", e.target.value) }
            />
          </div>

          <div className="field accept-terms">
            <div>
              <div className="switch-wrapper">
                <div className="flex-box items-center">
                  <label className="switch">
                    <input
                      id="accept-terms"
                      type="checkbox"
                      name="acceptedTerms"
                      value="1"
                      defaultChecked={acceptedTerms}
                      onChange ={ (e) => this.props.updateStep("emailProps", "acceptedTerms", e.target.checked) }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div  id="term-text"
                      className="switch-text"
                      onClick={() => {
                        document.getElementById('open-terms-modal').click()
                      }}>
                  { appProps.translations.checkout.accept_terms }
                </div>
              </div>
            </div>
          </div>

          <div className="field button-wrapper">
            <a className="button" onClick={ () => { this.handleClick() } }>
              { appProps.translations.checkout.continue_button }
            </a>
          </div>

        </div>

      </div>
    )
  }
}
