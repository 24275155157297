import React from "react"
import Modal from "react-modal"
import ReactDOM from "react-dom"
import Rails from "rails-ujs"

const customStyles = {
  content : {
    width                 : '80%',
    height                : '80vh',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    padding               : '40px 50px',
    transform             : 'translate(-50%, -50%)',
    background            : 'rgba(255,255,255,1)',
    borderRadius          : '2px'
  }
}

Modal.setAppElement('#terms-modal-place')

export default class TermsModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalIsOpen: false
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal() {
    this.setState({modalIsOpen: true})
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
    })
  }

  render() {
    const { legalTerms } = this.props

    return (
      <div>
        <button type="button" onClick={this.openModal} id="open-terms-modal" style={{display: 'none'}}></button>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}>

          <div className="flex-box content-end close-icon-wrapper">
            <i className="close_icon left" onClick={this.closeModal}></i>
          </div>
          { legalTerms  && <div dangerouslySetInnerHTML={{__html: legalTerms}} className="text"></div> }
        </Modal>
      </div>
    )
  }
}
