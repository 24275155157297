import React, { useState, useEffect, useRef } from "react"
import { debounceEventHandler, getTranslatableField, constructTranslatableProductLink } from '../../helpers/utils'
import cookie from "react-cookies"

export default function ProductSearch({ appProps, keyword, autoFocus }) {
  const [searchResults, setSearchResults] = useState({ products: [], similar: [], popular: [], categories: [] })
  const [successfulSearches, setSuccessfulSearches] = useState([])
  const [showEmptyProductsBox, setShowEmptyProductsBox] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const successfulSearchesRef = useRef(successfulSearches)

  const onUnmountOrUnload = searchesArray => {
    return () => {
      if (appProps.advancedSearchAllowed) {
        const searchesToSave = []
        searchesArray.sort((a, b) => a.length > b.length ? 1 : -1)

        while (searchesArray.length) {
          if (!searchesArray.some((search, index) => index !== 0 && search.includes(searchesArray[0]))) {
            searchesToSave.push(searchesArray[0])
          }
          searchesArray.shift()
        }

        updateCookie(searchesToSave)

        if(searchesToSave.length > 0) {
          let fd = new FormData()
          fd.append('searches_to_save', JSON.stringify(searchesToSave))

          Rails.ajax({
            type: "POST",
            url: `/update_search_terms`,
            contentType: "application/json; charset=UTF-8",
            dataType: "json",
            data: fd
          })
        }
      }
    }

  }

  useEffect(() => {
    const handleUnload = onUnmountOrUnload(successfulSearches.slice())

    successfulSearchesRef.current = successfulSearches
    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload)
    }
  }, [successfulSearches])

  useEffect(() => {
    return () => {
      onUnmountOrUnload(successfulSearchesRef.current.slice())()
    }
  }, [])

  function searchProducts(keyword) {
    Rails.ajax({
      type: "GET",
      url: `${appProps.productSearchPath}?keyword=${keyword}`,
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      success: res => {
        if (res.products) {
          setSearchResults({ products: res.products, similar: res.similar, popular: res.popular, categories: res.categories })
          setShowEmptyProductsBox(true)
          if (res.products.length > 0) {
            setSuccessfulSearches([...successfulSearches, keyword])
          }
        }
      }
    })
  }

  function updateCookie(keywords) {
    const recentSearchCookie = cookie.load('recentSearches') || [];

    keywords.forEach(keyword => {
      const idx = recentSearchCookie.findLastIndex(item => item.toLowerCase() === keyword.toLowerCase())
      recentSearchCookie.unshift(keyword)
      if (idx > -1) {
        recentSearchCookie.splice(idx + 1, 1)
      } else {
        if (recentSearchCookie.length > 5) {
          recentSearchCookie.pop()
        }
      }
    })

    cookie.save('recentSearches', recentSearchCookie, { path: '/' })
  }

  const handleAutocompleteInput = (event) => {
    let value = event.target.value.trim()
    if (value.length > 1) {
      searchProducts(value)
      setSearchValue(value)
    } else {
      setSearchResults({ products: [], similar: [], popular: [], categories: [] })
      setShowEmptyProductsBox(false)
    }
  }

  const renderProductItem = (product, index) => {
    let imageEntity = product.images[0]

    return (
      <a href={ constructTranslatableProductLink(product, appProps.currentLocale, appProps.defaultLocale, appProps.localeUrlPresent) } className="product-item" key={index} rel="nofollow noindex">
        <div className="image-wrapper">
          <img src={ imageEntity ? imageEntity.thumbnail : 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg' } />
        </div>

        <div className="details-wrapper">
          <div className="presentation">
            { getTranslatableField(product, 'title', appProps.currentLocale, false) }
          </div>
          <div className="prices-wrapper">
            <div className="display-price">
              { product.displayTotalPrice }
            </div>
            { product.onSale &&
              ( appProps.showTextInsteadOfCompareToPrice ?
                <div className="compare-to-price-wrapper">
                  <div className='compare-to-price-text'>{ appProps.translations.products.discount_price }</div>
                </div> :
                <div className="compare-to-price-wrapper">
                  <div className="compare-to-price">{ product.displayCompareToPrice }</div>
                  <div className="compare-to-price-percent">{`(-${Math.round(product.onSalePercentage)}%)`}</div>
                </div>
              )
            }
          </div>
          <div className="sku">
            { appProps.translations.products.sku }: <b>{ product.sku }</b>
          </div>
        </div>
      </a>
    )
  }

  const renderSearchBlocks = (category, idx) => {
    let items = []
    switch (category) {
      case 'recent':
        items = cookie.load('recentSearches') || []
        break
      case 'similar':
        items = searchResults.similar
        break
      case 'popular':
        items = searchResults.popular
    }

    return items.length > 0 && (
      <div className={`search-block flex-box flex-column ${category}-searches`} key={ idx }>
        <div className='title flex-box flex-column'>
          { appProps.translations.product_search[`${category}_searches`] }
        </div>
        <div className='flex-box flex-column'>
          { items.map((item) => (
            <a href={`${appProps.productsPath}?keyword=${item}`} key={item} rel='noindex nofollow noreferrer'>
              { item }
            </a>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="search-box-wrapper">
      <div className="search-input-wrapper">
        <div className="search-input">
          <input
            autoFocus
            type="search"
            className="search-box"
            placeholder={ appProps.translations.products.search_products }
            onChange={ debounceEventHandler(handleAutocompleteInput, 500) }
            defaultValue={ keyword || '' }
            onKeyPress={(event) => {
              if(event.charCode == 13) {
                const value = event.target.value

                if(value.length > 1) {
                  window.location.href = `${appProps.productsPath}?keyword=${value}`
                }
              }
            }}
          />
          <i className="search_icon fa-light fa-search"></i>
          <i className="search_arrow fa-light fa-chevron-right" onClick={() => {
            window.location.href = `${appProps.productsPath}?keyword=${searchValue}`
          }}></i>
        </div>

        { (searchResults.products.length > 0 || (searchResults.products.length === 0 && showEmptyProductsBox)) &&
          <>
            <div className={`search-results flex-box flex-column ${searchResults.products.length > 0 ? "animated fadeIn" : ""}`}>
              <div className='flex-box'>
                <div className="products-block flex-1">
                  { searchResults.products.length > 0 &&
                    <>
                      { searchResults.products.map(renderProductItem) }
                    </>
                  }
                  { searchResults.products.length === 0 && appProps.translations.products.no_products_found }
                </div>
                { appProps.advancedSearchAllowed &&
                  <div className="searches-block">
                    { ["recent", "similar", "popular"].map(renderSearchBlocks) }
                  </div>
                }
              </div>
              { appProps.advancedSearchAllowed && searchResults.categories.length > 0 &&
                <div className='flex-box flex-column categories-block-wrapper'>
                  <div style={{ padding: '10px 0px', fontWeight: 700 }}>{`${appProps.translations.product_search.found_in_categories}:`}</div>
                  <div className="categories-block flex-box flex-wrap items-center content-start">
                    { searchResults.categories.map((category, idx) => (
                      <a
                        key={idx}
                        className='category-search-result button inverted'
                        style={{ padding: '7px 12px' }}
                        href={`${appProps.productsPath}/c/${category.slug}?keyword=${successfulSearches.slice(-1)}`}>
                          { category.title } ({ category.count })
                      </a>
                    ))}
                  </div>
                </div>
              }
            </div>
          </>
        }
      </div>
    </div>
  )
}
