import React from "react"
import ShippingMethod    from "../methods/ShippingMethod"
import ErrorMessage from "../helpers/ErrorMessage"
import { scrollToStep } from "../helpers/scroller"
import { checkoutShippingMethodAnalytics } from '../../../helpers/analytics'
import { checkoutShippingMethodLegacyAnalytics } from '../../../helpers/legacy_analytics'
import Select from 'react-select'
import { customReactSelectStyles, customReactSelectTheme, formatPriceWithCurrency } from '../../../helpers/utils'

export default class ShippingStep extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      cartSubTotal: 0.0,
      cartWeight: 0.0,
      shippingMethods: [],
      offerMultipleShipmentsOption: false
    }
  }

  componentDidMount(){
    scrollToStep("shipping-step")

    const { selectedMethod, cartSubTotal, cartWeight, setCartLoader } = this.props
    setCartLoader(true)
    this.populateShippingMethods(cartSubTotal, cartWeight, selectedMethod)
  }

  componentDidUpdate(){
    const { selectedMethod, cartSubTotal, cartWeight, setCartLoader, cartLoading } = this.props

    if(cartSubTotal !== this.state.cartSubTotal || cartWeight !== this.state.cartWeight){
      if(!cartLoading){
        setCartLoader(true)
      }
      this.populateShippingMethods(cartSubTotal, cartWeight, selectedMethod)
    }
  }

  populateShippingMethods(cartSubTotal, cartWeight, selectedMethod) {
    const { setCartLoader, addressParams, updateSelectedShipping, updateMultipleShipmentsOption } = this.props

    let data = new FormData()
    data.append("state", addressParams.area)
    data.append("country", addressParams.country)
    data.append("postal_code", addressParams.postalCode)

    Rails.ajax({
      url: this.props.adminOrderNumber ? `/admin/${ReactiveCore.locale}/checkout/${this.props.adminOrderNumber}/get_shipping_methods` : `/checkout/get_shipping_methods`,
      type: 'POST',
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      data: data,
      success: res => {
        const stateUpdateData = {
          cartSubTotal: cartSubTotal,
          cartWeight: cartWeight,
          shippingMethods: res.shippingMethods,
          offerMultipleShipmentsOption: res.offerMultipleShipmentsOption
        }

        const selected = res.shippingMethods.find( shippingMethod => (shippingMethod.id === selectedMethod.id) && (!shippingMethod.missing_amount || shippingMethod.missing_amount <= 0) )
        const selectedSmartpoint = this.props.selectedSmartpoint

        this.setState(stateUpdateData, () => {
          setCartLoader(false)
          scrollToStep("shipping-step")
          updateSelectedShipping(selected || {}, selectedSmartpoint)
          if(!res.offerMultipleShipmentsOption) {
            updateMultipleShipmentsOption(false)
          }
        })
      }
    })
  }

  clearErrors(){
    document.querySelectorAll('[id$="-error"]').forEach(errorElement => errorElement.remove())
    document.querySelectorAll('input').forEach(element => element.style.borderColor = '')
  }

  handleSelectShippingMethod(shippingMethod, selectedSmartpoint) {
    const { updateSelectedShipping, multipleShipmentsOptionEnabled } = this.props

    const shippingMethodGrossCost = multipleShipmentsOptionEnabled ? shippingMethod.multiple_shipments_gross_cost : shippingMethod.gross_cost

    this.clearErrors()
    if (multipleShipmentsOptionEnabled !== null) {
      updateSelectedShipping(shippingMethod, selectedSmartpoint)
    }
  }

  handleClick(){
    const { selectedMethod, proceedToNextStep, googleAnalyticsId, googleMeasurementId } = this.props

    if(selectedMethod.type === "LocalPickup" || Object.keys(selectedMethod).length === 0){
      ErrorMessage("shipping-methods-list", "red", "shipping-methods-list-error", `${this.props.translations.checkout.general_error} ${this.props.translations.checkout.shipping_method}`, "red")
      return
    }
    this.props.googleMeasurementId ? checkoutShippingMethodAnalytics(selectedMethod) : checkoutShippingMethodLegacyAnalytics(selectedMethod);

    proceedToNextStep()
  }

  render() {
    const { selectedMethod, updateSelectedShipping, multipleShipmentsOptionEnabled, updateMultipleShipmentsOption, currency, translations } = this.props
    const maxShownMethods = 10
    let shippingOptions = []

    if (this.state.shippingMethods.length > maxShownMethods) {
      shippingOptions = this.state.shippingMethods.map( option => {
        const shippingMethodGrossCost = multipleShipmentsOptionEnabled ? option.multiple_shipments_gross_cost : option.gross_cost
        let title = option.title

        if (shippingMethodGrossCost !== null && shippingMethodGrossCost > 0) {
          title += `, + ${ formatPriceWithCurrency(shippingMethodGrossCost.toFixed(2), currency) }`
        }

        if (shippingMethodGrossCost === null && option.missing_amount) {
          title += `, ${ translations.checkout.shipping_method_missing_amount } ${ formatPriceWithCurrency(option.missing_amount.toFixed(2), currency) }`
        }

        return {
          value: option.id,
          label: title,
          isDisabled: shippingMethodGrossCost === null
        }
      })
    }

    return(
      <div id="shipping-step" className="checkout-step shipping card">
        <div className="step-title">
          { this.props.translations.checkout.shipping_method }
        </div>

        {this.state.offerMultipleShipmentsOption &&
          <div className="checkout-step delivery-details">
            <div className="delivery-method">
              <div
                className={ `tile delivery one_shipment-icon ${!multipleShipmentsOptionEnabled ? 'selected' : ''}` }
                onClick={(e) => { updateMultipleShipmentsOption(false) }}
              >
                { this.props.translations.checkout.one_shipment }
              </div>
              <div
                className={ `tile pickup multiple_shipments-icon ${multipleShipmentsOptionEnabled ? 'selected' : ''}` }
                onClick={(e) => { updateMultipleShipmentsOption(true) }}
              >
                { this.props.translations.checkout.multiple_shipments }
              </div>
            </div>
          </div>
        }

        <div id="shipping-methods-list" className="shipping-methods">
          { this.state.shippingMethods.length > maxShownMethods &&
            <>
              <Select
                key={ `maaaa${selectedMethod}` }
                theme={ customReactSelectTheme }
                styles={ customReactSelectStyles }
                isSearchable={ false }
                options={ shippingOptions }
                value={ shippingOptions.find( option => selectedMethod && option.value === selectedMethod.id ) || {} }
                onChange={ option => this.handleSelectShippingMethod(this.state.shippingMethods.find( shippingMethod => shippingMethod.id === option.value  )) }
              />

              { selectedMethod && selectedMethod.description &&
                <div dangerouslySetInnerHTML={{ __html: selectedMethod.description }} className='description'/>
              }
            </>
          }

          { this.state.shippingMethods.length <= maxShownMethods && this.state.shippingMethods.map( (method, index) => (
            <ShippingMethod
              key={ index }
              selectedMethod={ selectedMethod }
              shippingMethod={ method }
              translations={ this.props.translations }
              handleSelectShippingMethod={ this.handleSelectShippingMethod.bind(this) }
              currency={ currency }
              multipleShipmentsOptionEnabled={ multipleShipmentsOptionEnabled }
              address = { this.props.addressParams }
              selectedSmartpoint = { this.props.selectedSmartpoint }
              acsSmartpointsPath = { this.props.acsSmartpointsPath }
            />
          ))}
        </div>

        {
          this.state.shippingMethods.filter(method => multipleShipmentsOptionEnabled ? (method.multiple_shipments_gross_cost !== null) : (method.gross_cost !== null)).length > 0 &&
            <div className="fields">
              <div className="field button-wrapper">
                <a className="button" onClick={ () => { this.handleClick() } }>
                  { this.props.translations.checkout.continue_button }
                </a>
              </div>
            </div>
        }

      </div>
    )
  }
}
