import React, { useState, useEffect, useRef } from "react"
import { getTranslatableField } from "../../helpers/utils"
import { FlashMessage } from "@reactiveonline/frontend_shared_components"

export default function SideFilters({
  appProps, appliedFilterOptions, productFilters, categoryMenusSlugs,
  filtersAllowed, preConstructFilterUrl, submitFilterOption, clearOneTypeFilter,
  toggleFiltersSidebar, setToggleFiltersSidebar, relatedCategoriesPath,
  upperPriceLimit, setUpperPriceLimit, lowerPriceLimit, setLowerPriceLimit, keyword
}) {
  const [categoryMenus, setCategoryMenus]                     = useState([])
  const [togglePriceRange, setTogglePriceRange]               = useState(true)
  const [toggleCategories, setToggleCategories]               = useState(true)
  const [toggleBrands, setToggleBrands]                       = useState(false)
  const [toggleOptionKeys, _setToggleOptionKeys]              = useState(Object.keys(appliedFilterOptions))
  const [toggleMobileSidebar, setToggleMobileSidebar]         = useState(false)
  const [isMobile, setIsMobile]                               = useState(false)
  const [relatedCategories, setRelatedCategories]             = useState([])
  const [toggleRelatedCategories, setToggleRelatedCategories] = useState(false)
  const [showAllCategories, setShowAllCategories]             = useState(false)
  const [selectedRootCategoryMenu, setSelectedRootCategoryMenu] = useState(findSelectedRootCategoryMenu)

  const flashMessageRef = useRef(null)
  const didFetchCategoryMenusRef = useRef(null)

  const visibleCategoryMenusCount = categoryMenus.filter( item => item.productCount ).length
  const activeCategoryMenusSlugs = categoryMenusSlugs ? categoryMenusSlugs.split('/') : []

  useEffect(() => {
    if (appProps.currentDevice != 'desktop') {
      setIsMobile(true)
      setToggleMobileSidebar(false)
    }
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", () => {
      if(appliedFilterOptions && Object.keys(appliedFilterOptions).length > 0) {
        window.location.reload()
      }
    })

    if (appProps.showCategories) {
      let url = appProps.categoryMenusPath
      let queryParams = ''
      let hasQueryParams = false

      if (appliedFilterOptions.on_sale) {
        hasQueryParams = true
        queryParams = queryParams + '?on_sale=true'
      }

      if (appliedFilterOptions.price_range) {
        if (!hasQueryParams) {
          queryParams = queryParams + '?'
        }
        if (lowerPriceLimit !== ""){
          queryParams = queryParams + `${hasQueryParams ? "&": ""}lower_price_limit=${lowerPriceLimit}`
          hasQueryParams = true
        }
        if (upperPriceLimit !== ""){
          queryParams = queryParams + `${hasQueryParams ? "&": ""}upper_price_limit=${upperPriceLimit}`
          hasQueryParams = true
        }
      }

      if (appliedFilterOptions.brand && appliedFilterOptions.brand.length > 0) {
        if (!hasQueryParams) {
          queryParams = queryParams + '?'
        }
        queryParams = queryParams + `${hasQueryParams ? "&": ""}brands=${appliedFilterOptions.brand.join(',')}`
        hasQueryParams = true
      }

      if (keyword){
        queryParams = queryParams + `${hasQueryParams ? "&": "?"}keyword=${keyword}`
      }

      url = url + queryParams

      Rails.ajax({
        type: 'GET',
        url: url,
        success: res => {
          setCategoryMenus(res.categoryMenus)
          if (!didFetchCategoryMenusRef.current) {
            didFetchCategoryMenusRef.current = true
          }
        }
      })
    }
  }, [getPriceRangeValue(), getOnSaleValue(), getBrandsValue()]);

  useEffect(() => {
    if (appProps.showCategories && categoryMenusSlugs) {
      Rails.ajax({
        type: 'GET',
        url: `${ relatedCategoriesPath }?slug=${ categoryMenusSlugs }`,
        success: res => {
          if (res.relatedCategories) {
            setRelatedCategories(res.relatedCategories.sort((relatedCategoryA, relatedCategoryB) => {
              const relatedTitleA = getTranslatableField(relatedCategoryA, 'title', appProps.currentLocale, false).toLowerCase()
              const relatedTitleB = getTranslatableField(relatedCategoryB, 'title', appProps.currentLocale, false).toLowerCase()
              if (relatedTitleA < relatedTitleB) {
                  return -1;
              }
              if (relatedTitleA > relatedTitleB) {
                  return 1;
              }
              return 0;
            }))

            if (res.relatedCategories.length > 0) {
              setToggleRelatedCategories(true)
            }
          }
        }
      })
    }
  }, [])

  useEffect( () => {
    if (didFetchCategoryMenusRef.current) {
      setSelectedRootCategoryMenu(findSelectedRootCategoryMenu())
    }
  }, [categoryMenus])

  function findSelectedRootCategoryMenu(items = categoryMenus, depth = 0) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      if (activeCategoryMenusSlugs[activeCategoryMenusSlugs.length - 1] === item.slug) {
        return item

      } else {
        const categoryMenu = findSelectedRootCategoryMenu(item.items, depth + 1)
        if (categoryMenu) {
          return item
        }
      }
    }

    return null
  }

  function getPriceRangeValue() {
    return appliedFilterOptions.price_range ? appliedFilterOptions.price_range[0].lowerPriceLimit + "-" + appliedFilterOptions.price_range[0].upperPriceLimit : ""
  }

  function getOnSaleValue() {
    return appliedFilterOptions.on_sale ? appliedFilterOptions.on_sale[0] : ""
  }

  function getBrandsValue() {
    return appliedFilterOptions.brand ? JSON.stringify(appliedFilterOptions.brand) : ""
  }

  function generateLink(itemIndexPath) {
    let categoryMenu = categoryMenus[itemIndexPath[0]]
    let link = categoryMenu.slug
    itemIndexPath.slice(1).forEach( index => {
      categoryMenu = categoryMenu.items[index]
      link += '/' + categoryMenu.slug
    })
    return link
  }

  function handleShowChildren(itemIndexPath) {
    let updatedCategoryMenus = [...categoryMenus]

    let categoryMenuToUpdate = categoryMenus[itemIndexPath[0]]
    itemIndexPath.slice(1).forEach( index => {
      categoryMenuToUpdate = categoryMenuToUpdate.items[index]
    })
    categoryMenuToUpdate.showChildren = !categoryMenuToUpdate.showChildren
    setCategoryMenus(updatedCategoryMenus)
  }

  function setToggleOptionKeys(optionKey) {
    if (toggleOptionKeys.includes(optionKey)) {
      _setToggleOptionKeys( (prevState) => prevState.filter(key => key !== optionKey ))
    } else {
      _setToggleOptionKeys( (prevState) => [...prevState, optionKey] )
    }
  }

  function renderRelatedCategories() {
    return (
      <>
        { relatedCategories.map((related, index)=> {
            const localePrefix = appProps.localeUrlPresent && appProps.currentLocale ? `/${ appProps.currentLocale }` : ''
            const relatedTitle = getTranslatableField(related, 'title', appProps.currentLocale)
            const relatedSlug =  getTranslatableField(related.categoryMenu, 'slug', appProps.currentLocale)

            if (related.productCount > 0 && relatedTitle && relatedTitle !== '') {
              return (
                <React.Fragment key={ index }>
                  <a
                    href={ `${ localePrefix }/products/c/${ relatedSlug }` }
                    className='flex-box items-center content-space-between'
                    rel="nofollow noindex">
                      <div className='name'>{ relatedTitle }</div>
                      <div className='count'>({ related.productCount })</div>
                  </a>
                </React.Fragment>
              )
            }
          })
        }
      </>
    )
  }

  function renderCategoryMenus(items, depth = 0, itemIndexPath = [], isRoot = false) {
    let visibleCategoriesCount = 0

    return (
      <>
        { depth === 0 && selectedRootCategoryMenu && !isRoot && renderCategoryMenus(items, depth, itemIndexPath, true) }

        { items.map( (item, index) => {
          const itemTitle = getTranslatableField(item, 'title', appProps.currentLocale, false)

          if (((!isRoot && (!selectedRootCategoryMenu || item.id !== selectedRootCategoryMenu.id)) || (isRoot && (depth !== 0 || item.id === selectedRootCategoryMenu.id))) && item.productCount && itemTitle && (depth !== 0 || showAllCategories || visibleCategoriesCount < 10)) {
            visibleCategoriesCount++
            const link = generateLink([...itemIndexPath, index])
            const localePrefix = appProps.localeUrlPresent && appProps.currentLocale ? `/${appProps.currentLocale}` : ''
            let url = categoryMenusSlugs === link ? `${localePrefix}/products` : `${localePrefix}/products/c/${ link }`

            if (appliedFilterOptions["brand"]) {
              let formattedKeys = "brand"
              let formattedValues = appliedFilterOptions["brand"].join('+')

              if (formattedValues !== '') {
                url += `/f/${formattedValues}/${formattedKeys}`
              }
            }

            let hasQueryParams = false
            let queryParams = ""
            if (appliedFilterOptions.on_sale) {
              hasQueryParams = true
              queryParams = queryParams + '?on_sale=true'
            }

            if (appliedFilterOptions.price_range) {
              if (!hasQueryParams) {
                queryParams = queryParams + '?'
              }
              if (lowerPriceLimit !== ""){
                queryParams = queryParams + `${hasQueryParams ? "&": ""}lower_price_limit=${lowerPriceLimit}`
                hasQueryParams = true
              }
              if (upperPriceLimit !== ""){
                queryParams = queryParams + `${hasQueryParams ? "&": ""}upper_price_limit=${upperPriceLimit}`
                hasQueryParams = true
              }
            }

            if (keyword){
              queryParams = queryParams + `${hasQueryParams ? "&": "?"}keyword=${keyword}`
            }

            url += queryParams

            return (
              <React.Fragment key={ item.id }>
                <div className='flex-box'>
                  <a
                    style={{ paddingLeft: depth > 0 ? 25 * depth : 15 }}
                    className={`flex-box items-center content-space-between ${ activeCategoryMenusSlugs[activeCategoryMenusSlugs.length - 1] === item.slug ? 'active' : ''} `}
                    href={ url }
                    rel='nofollow noindex'
                    onClick={ event => {
                      if (!item.categoryId && !item.items.length) {
                        event.preventDefault()
                      }
                    }}
                  >
                    <div className='flex-box items-center'>
                      <div className='category-name'>{ itemTitle }</div>
                      { item.items.length > 0 && item.productCount > 0 && item.items.filter( child => child.productCount )[0] &&
                        <i
                          style={{ marginLeft: 5 }}
                          className={`pointer fa-light fa-${item.showChildren || activeCategoryMenusSlugs.includes(item.slug) ? 'chevron-up' : 'chevron-down' } `}
                          onClick={ event => {
                            event.preventDefault()
                            if (!isRoot || !activeCategoryMenusSlugs.includes(item.slug)) {
                              handleShowChildren([...itemIndexPath, index])
                            }
                          }}
                        />
                      }
                    </div>
                    <div>{ item.productCount ? ` (${ item.productCount })` : '' }</div>
                  </a>
                </div>

                { item.items.length > 0 && (item.showChildren || activeCategoryMenusSlugs.includes(item.slug)) &&
                  renderCategoryMenus(item.items, depth + 1, [...itemIndexPath, index], isRoot)
                }
              </React.Fragment>
            )
          }
        })}
      </>
    )
  }

  function applyPriceRangeFilter() {
    if (!((lowerPriceLimit !== "" && upperPriceLimit !== "") && (parseFloat(lowerPriceLimit) > parseFloat(upperPriceLimit)))) {
      submitFilterOption('price_range', { lowerPriceLimit: lowerPriceLimit, upperPriceLimit: upperPriceLimit })
    } else {
      flashMessageRef.current.show(appProps.translations.flash_messages.price_range_limits_error, 'error')
    }
  }

  function renderPriceRangeFilter() {
    return (
      <div className="flex-box content-space-between price-range">
        <div className="flex-box content-space-between items-center" style={{ padding: '0px 15px' }}>
          <div className="flex-box content-space-between items-center">
            <div>
              <input type="text" value={ lowerPriceLimit } id="price_range_lower_limit" onChange={(event) => {
                let value = event.target.value
                if(value === '' || value.match(/^[0-9.,]+$/)) {
                  value = value.replace(/,/g, '.')
                  setLowerPriceLimit(value)
                }
              }} />
            </div>
            <div style={{ padding: '0px 7px' }}>
              -
            </div>
            <div>
              <input type="text" value={upperPriceLimit} id="price_range_upper_limit" onChange={(event) => {
                let value = event.target.value
                if(value === '' || value.match(/^[0-9.,]+$/)) {
                  value = value.replace(/,/g, '.')
                  setUpperPriceLimit(value)
                }
              }} />
            </div>
          </div>
          <div className='btn plain flex-box content-center items-center'>
            <i className='pointer fa-light fa-chevron-right flex-box content-center items-center' onClick={ applyPriceRangeFilter } />
          </div>
        </div>
      </div>
    )
  }

  function renderOnSaleFilter() {
    const { onSale } = productFilters

    let onSaleItem = null
    if(onSale == undefined) {
      return null
    }

    if(onSale == 0) {
      onSaleItem =
        <div className="option disabled-filter">{`${appProps.translations.products.on_sale} (${onSale})`}</div>
    } else {
      onSaleItem =
        <a href={preConstructFilterUrl('on_sale', 'true')}
           className={`option ${appliedFilterOptions.on_sale ? 'active' : ''}`}
           rel="nofollow noindex"
           onClick={(e) => {
          e.preventDefault()

          submitFilterOption('on_sale', 'true')
        }}>
          {`${appProps.translations.products.on_sale} (${onSale})`}
        </a>
    }

    return (
      <div className="additional-filters">
        { onSaleItem }
      </div>
    )
  }

  function renderBrandFilters() {
    const { brandFilters } = productFilters

    const brandKeys = Object.keys(brandFilters)

    if(brandKeys.length > 0) {
      return (
        <div className="filters_wrapper checkboxes">
          <div className='sidebar-title flex-box items-center content-space-between pointer' onClick={ ()=> setToggleBrands(prevState => !prevState) }>
            <div className='flex-box items-center'>
              <div>{ appProps.translations.products.brand }</div>
              { appliedFilterOptions.brand &&
                <div className="pointer clear-option-type" style={{ marginLeft: 5 }} onClick={() => {
                  clearOneTypeFilter('brand')
                }}>
                  { appProps.translations.products.clear_one_type_filter }
                </div>
              }
            </div>
            <i className={`pointer fa-light fa-${toggleBrands ? 'chevron-up' : 'chevron-down' }`} />
          </div>
          { toggleBrands &&
            <>
              { brandKeys.map((k, index) => {
                  const filterValues = JSON.parse(k)

                  let filterTranslations = JSON.parse(filterValues[0])
                  let title = filterTranslations[appProps.currentLocale] && filterTranslations[appProps.currentLocale] !== '' ? filterTranslations[appProps.currentLocale] : filterTranslations[appProps.defaultLocale]

                  const productsCount = parseInt(brandFilters[k])

                  if(productsCount > 0) {
                    return (
                      <a key={index} href={preConstructFilterUrl('brand', filterValues[1])}
                         className={`option checkbox ${appliedFilterOptions.brand && appliedFilterOptions.brand.indexOf(filterValues[1]) > -1 ? 'active' : ''}`}
                         rel="nofollow noindex"
                         onClick={(e) => {
                        e.preventDefault()
                        submitFilterOption('brand', filterValues[1])
                      }}>
                        {`${title} (${brandFilters[k]})`}
                      </a>
                    )
                  }
                })
              }
            </>
          }
        </div>
      )
    } else {
      return null
    }
  }

  function renderOptionFilters() {
    const { optionFilters, optionTypeNames } = productFilters

    return Object.keys(optionFilters).filter(optionTypeKey => Object.keys(optionFilters[optionTypeKey]).length > 0).map((optionTypeKey, index) => {
      const optionKeys = Object.keys(optionFilters[optionTypeKey])
      let optionTypeTranslations = JSON.parse(optionTypeNames[optionTypeKey])
      let optionTypeTitle = optionTypeTranslations[appProps.currentLocale] && optionTypeTranslations[appProps.currentLocale] !== '' ? optionTypeTranslations[appProps.currentLocale] : optionTypeTranslations[appProps.defaultLocale]

      return (
        <div className="filters_wrapper checkboxes" key={index}>
          <div className='sidebar-title flex-box items-center content-space-between pointer' onClick={ ()=> setToggleOptionKeys(optionTypeKey) }>
            <div className='flex-box items-center'>
              <div>{optionTypeTitle}</div>
              { appliedFilterOptions[optionTypeKey] &&
                <div className="pointer clear-option-type" style={{ marginLeft: 5 }} onClick={() => {
                  clearOneTypeFilter(optionTypeKey)
                }}>
                  { appProps.translations.products.clear_one_type_filter }
                </div>
              }
            </div>
            <i className={`pointer fa-light fa-${toggleOptionKeys.includes(optionTypeKey) ? 'chevron-up' : 'chevron-down' }`}/>
          </div>
          { toggleOptionKeys.includes(optionTypeKey) &&
            <div className="option-wrapper">
              {
                optionKeys.sort((optionKeyA, optionKeyB) => {
                  const optionValuesA = JSON.parse(optionKeyA)
                  const optionValuesB = JSON.parse(optionKeyB)

                  let filterTranslationsA = JSON.parse(optionValuesA[0])
                  let filterTranslationsB = JSON.parse(optionValuesB[0])

                  let titleA = filterTranslationsA[appProps.currentLocale] && filterTranslationsA[appProps.currentLocale] !== '' ? filterTranslationsA[appProps.currentLocale] : filterTranslationsA[appProps.defaultLocale]
                  titleA = titleA.toLowerCase()
                  let titleB = filterTranslationsB[appProps.currentLocale] && filterTranslationsB[appProps.currentLocale] !== '' ? filterTranslationsB[appProps.currentLocale] : filterTranslationsB[appProps.defaultLocale]
                  titleB = titleB.toLowerCase()

                  if (titleA < titleB) {
                    return -1;
                  }
                  if (titleA > titleB) {
                      return 1;
                  }
                  return 0;

                }).map((optionKeys, optIndex) => {
                  const optionValues = JSON.parse(optionKeys)

                  let filterTranslations = JSON.parse(optionValues[0])
                  let title = filterTranslations[appProps.currentLocale] && filterTranslations[appProps.currentLocale] !== '' ? filterTranslations[appProps.currentLocale] : filterTranslations[appProps.defaultLocale]

                  const productsCount = parseInt(optionFilters[optionTypeKey][optionKeys])

                  if(productsCount > 0) {
                    return (
                      <a key={optIndex} href={preConstructFilterUrl(optionTypeKey, optionValues[1])}
                         className={`option checkbox ${optionTypeKey} ${appliedFilterOptions[optionTypeKey] && appliedFilterOptions[optionTypeKey].indexOf(optionValues[1]) > -1 ? 'active' : ''}`}
                         rel="nofollow noindex"
                         onClick={(e) => {
                        e.preventDefault()
                        submitFilterOption(optionTypeKey, optionValues[1])
                      }}>
                        {`${title} (${optionFilters[optionTypeKey][optionKeys]})`}
                      </a>
                    )
                  }
                })
              }
            </div>
          }
        </div>
      )
    })
  }

  return (
    <>
      { appProps.currentDevice != 'desktop' &&
        <div className='toggle-mobile-sidebar-wrapper flex-box content-center items-center'>
          <div className='toggle-button' onClick={ ()=> (setToggleMobileSidebar(prevState => !prevState), setIsMobile(false)) }>
            { appProps.translations.products.filters_title }
          </div>
        </div>
      }
      <div className={`sidebar-wrapper ${isMobile ? 'closed' : ''} ${toggleMobileSidebar ? 'mobile open' : '' } ${toggleFiltersSidebar ? 'open' : ''}` }>
        <div className="sidebar-container">
          { appProps.currentDevice != 'desktop' &&
            <>
              <div  className='toggle_icon flex-box items-center'
                    style={{ position: 'absolute', top: 20, left: 20, fontSize: 18, fontWeight: 700 }}>
                { appProps.translations.products.filters_title }
              </div>
              <div className='close_icon pointer' onClick={ ()=> (setToggleMobileSidebar(prevState => !prevState), setIsMobile(true)) } />
            </>
          }
          { appProps.showCategories && relatedCategories.length > 0 && relatedCategories.some(category => category.productCount && category.productCount > 0) &&
            <div className="categories_wrapper">
              <div className="sidebar-title flex-box items-center content-space-between pointer" onClick={ ()=> setToggleRelatedCategories(prevState => !prevState) }>
                <div>{ appProps.translations.products.related_categories }</div>
                <i className={`pointer fa-light fa-${ toggleRelatedCategories ? 'chevron-up' : 'chevron-down' }`} />
              </div>
              { toggleRelatedCategories && renderRelatedCategories() }
            </div>
          }
          { filtersAllowed && productFilters && productFilters.priceFilters &&
            <div className="categories_wrapper">
              <div className='sidebar-title flex-box items-center content-space-between pointer' onClick={ ()=> setTogglePriceRange(prevState => !prevState) }>
                <div>{ appProps.translations.products.price_range }</div>
                <i className={`pointer fa-light fa-${togglePriceRange ? 'chevron-up' : 'chevron-down' }`} />
              </div>
              { togglePriceRange && renderPriceRangeFilter() }
            </div>
          }
          { filtersAllowed && productFilters && productFilters.onSale !== null && renderOnSaleFilter() }
          { appProps.showCategories &&
            <div className="categories_wrapper">
              <div className='sidebar-title flex-box items-center content-space-between pointer' onClick={ ()=> setToggleCategories(prevState => !prevState) }>
                <div>{ appProps.translations.products.product_categories }</div>
                <i className={`pointer fa-light fa-${toggleCategories ? 'chevron-up' : 'chevron-down' }`} />
              </div>
              { toggleCategories &&
                <>
                  { renderCategoryMenus([...categoryMenus]) }
                  { visibleCategoryMenusCount > 10 &&
                    <div
                      className='pointer flex-box items-center content-center'
                      style={{ width: '100%', padding: '5px 10px', fontSize: 12, backgroundColor: appProps.frontendStyles.general.separatorsColor }}
                      onClick={ () => setShowAllCategories( prevState => !prevState ) }
                    >
                      <div style={{ marginRight: 10 }}>
                        { appProps.translations.general[showAllCategories ? 'show_less' : 'show_more'] }
                      </div>
                      <i className={ `fa-light fa-${ showAllCategories ? 'chevron-up' : 'chevron-down' }` }/>
                    </div>
                  }
                </>
              }
            </div>
          }
          { filtersAllowed && productFilters && productFilters.brandFilters && renderBrandFilters() }
          { filtersAllowed && productFilters && productFilters.optionFilters && renderOptionFilters() }
        </div>
      </div>
      <FlashMessage ref={ flashMessageRef } />
    </>
  )
}
