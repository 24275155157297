import React, { useState, useEffect } from "react"
import Select from "react-select"
import { Loader } from '@reactiveonline/frontend_shared_components'
import PaymentMethod from "../methods/PaymentMethod"
import Invoice from "../Invoice"
import { customReactSelectStyles, customReactSelectTheme, numberValidator } from "../../../helpers/utils"
import ErrorMessage from '../helpers/ErrorMessage'
import { scrollToStep } from '../helpers/scroller'
import { checkoutPaymentMethodAnalytics } from '../../../helpers/analytics'
import { checkoutPaymentMethodLegacyAnalytics } from '../../../helpers/legacy_analytics'

export default function BillingStep({
  paymentMethods, isDeliveryAddressUsedAsBilling, selectedMethod, shippingMethod, updateSelectedPayment, useDeliveryAddressAsBilling,
  contactlessEnabled, showContactless, updateContactless, stripePublishableKey, submitStripeCard, setStripeError, completeOrder, stripePaymentIntentClientSecret, stripePaymentIntentActionType, stripeSetupIntentClientSecret, stripeSetupIntentActionType,
  currency, translations, orderId, type, adminOrderNumber, updateStep, googleAnalyticsId, proceedToNextStep, deliveryProps, billingProps,
  invoice, canIssueInvoice, companyCheckout, showInvoiceForm, updateShowInvoiceForm, googleMeasurementId, installments, updateInstallments, appProps, requireMobilePhone, cardsPath, setCurrentCardPath, loading, setLoading, stripeBillingDetails
}) {
  const [availableCountries, setAvailableCountries] = useState([])
  const [availableStates, setAvailableStates] = useState([])
  const [statesRequired, setStatesRequired] = useState(false)
  const [showUseDeliveryAsBillingSwitch, setShowUseDeliveryAsBillingSwitch] = useState(false)
  const [showAddressForm, setShowAddressForm] = useState(false)
  const [clickedContinue, setClickedContinue] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedState, setSelectedState] = useState([])
  const [invoiceState, setInvoiceState] = useState({ companyName: companyCheckout ? billingProps.companyName : '', vatNumber: companyCheckout ? billingProps.vatNumber : '', additionalInfo: '', vatValidity: false })

  useEffect( ()=>{
    scrollToStep("billing-step")
    const selectedCountry = companyCheckout ? billingProps.country : deliveryProps.country
    const selectedArea = companyCheckout ? billingProps.area : deliveryProps.area

    if( selectedCountry || selectedArea ){
      Rails.ajax({
        type: "GET",
        url: adminOrderNumber ? `/admin/${ReactiveCore.locale}/checkout/${adminOrderNumber}/get_selected?numeric_code=${selectedCountry}&region_code=${selectedArea}` : `/checkout/get_selected?numeric_code=${selectedCountry}&region_code=${selectedArea}`,
        dataType: "json",
        success: res => {
          if(res.selectedCountry) {
            setSelectedCountry(res.selectedCountry)
            if(res.selectedCountry.states_required) {
              setStatesRequired(true)
              setSelectedState(res.selectedState)
            }
          }
        }
      })
    }
  },[] )

  useEffect( ()=> {
    if (availableCountries.length === 0) {
      if (deliveryProps.country) {
        setSelectedCountry(selectedCountry)
      }
    } else {
      if (!selectedCountry) {
        setSelectedCountry( availableCountries.find(c => c.value === deliveryProps.country) )
      }
    }
  },[selectedCountry])

  // Do not change this conditional logic
  useEffect( ()=> {
    if (!companyCheckout) {
      if (shippingMethod.type !== "LocalPickup" ) {
        setShowUseDeliveryAsBillingSwitch(!selectedMethod.billingAddressRequired)
        if (!isDeliveryAddressUsedAsBilling) {
          setShowAddressForm(true)
          billingAddressInit()
        }
        if ( selectedMethod.billingAddressRequired || (showInvoiceForm || !!invoice) ) {
          setShowAddressForm(true)
          useDeliveryAddressAsBilling(false)
          billingAddressInit()
        } else {
          if (!isDeliveryAddressUsedAsBilling) {
            setShowAddressForm(true)
            useDeliveryAddressAsBilling(false)
          } else {
            setShowAddressForm(false)
            useDeliveryAddressAsBilling(true)
          }
        }
      } else {
        if ( selectedMethod.billingAddressRequired || (showInvoiceForm || !!invoice) ) {
          setShowAddressForm(true)
          billingAddressInit()
        } else {
          setShowAddressForm(false)
        }
      }
    } else {
      setShowAddressForm(true)
    }
  },[showInvoiceForm, selectedMethod, isDeliveryAddressUsedAsBilling, showUseDeliveryAsBillingSwitch, isDeliveryAddressUsedAsBilling, invoice])

  function billingAddressInit(){
    const addressKeys = ['address', 'area', 'city', 'country', 'fullName', 'mobile', 'phone', 'postalCode']
    let addressProps = Object.assign({}, ...addressKeys.map( key => ({ [key]: billingProps[key] }) ))

    if (!addressKeys.find( key => addressProps[key] )) { // if all billingProps are empty then initialize from deliveryProps
      addressProps = Object.assign({}, ...addressKeys.map( key => ({ [key]: deliveryProps[key] }) ))
    }

    updateStep("billingProps", "address", addressProps.address)
    updateStep("billingProps", "area", addressProps.area)
    updateStep("billingProps", "city", addressProps.city)
    updateStep("billingProps", "country", addressProps.country)
    updateStep("billingProps", "fullName", addressProps.fullName)
    updateStep("billingProps", "mobile", addressProps.mobile.trim())
    updateStep("billingProps", "phone", addressProps.phone.trim())
    updateStep("billingProps", "postalCode", addressProps.postalCode)
  }

  function getAvailableCountries(){
    Rails.ajax({
      type: "GET",
      url: `/${ appProps.currentLocale }/countries`,
      dataType: "json",
      success: res => {
        setAvailableCountries(res.available_countries)
      }
    })
  }

  function getAvailableStates(selectedCountry){
    Rails.ajax({
      type: "GET",
      url: `/${ appProps.currentLocale }/countries/${ selectedCountry }/states`,
      dataType: "json",
      success: res => {
        setSelectedState(null)
        setStatesRequired(res.available_states.length > 0)
        setAvailableStates(res.available_states)
      }
    })
  }

  function handleCountryChange(selected) {
    setSelectedCountry(selected)
    getAvailableStates(selected.value)
    updateStep("billingProps", "country", selected.value)
    updateStep("billingProps", "area", '')
  }

  function handleStateChange(selected) {
    setSelectedState(selected)
    updateStep("billingProps", "area", selected.value)
  }

  function clearErrors(){
    document.querySelectorAll('[id$="-error"]').forEach(errorElement => errorElement.remove())
    document.querySelectorAll('input').forEach(element => element.style.borderColor = '')
  }

  function clearError(id){
    let errorElement = document.getElementById(`${id}-error`)
    if (errorElement) {
      errorElement.remove()
      errorElement.style.borderColor = ''
    }
  }

  function handleClick(){
    let noErrors = true
    clearErrors()

    if(Object.keys(selectedMethod).length === 0){
      ErrorMessage("payment-methods-list", "red", "payment-methods-list-error", `${ translations.checkout.please_choose_payment }`, "red")
      return null
    }

    googleMeasurementId ? checkoutPaymentMethodAnalytics(selectedMethod) : checkoutPaymentMethodLegacyAnalytics(selectedMethod)

    if ((selectedMethod.billingAddressRequired && !isDeliveryAddressUsedAsBilling) || showInvoiceForm) {
      if( billingProps.fullName.length === 0 ){
        ErrorMessage("billing-full-name", "red", "billing-full-name-error", `${translations.checkout.general_error} ${translations.checkout.full_name}`, "red")
        noErrors = false
      }
      if( billingProps.phone.trim().length > 0 && !numberValidator(billingProps.phone.trim()) ) {
        ErrorMessage("billing-phone", "red", "billing-phone-error", `${translations.checkout.phone_detailed_error}`, "red")
        noErrors = false

        const element = document.getElementById('phone')
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }
      if( billingProps.phone.trim().length === 0 ) {
        ErrorMessage("billing-phone", "red", "billing-phone-error", `${translations.checkout.general_error} ${translations.checkout.phone}`, "red")
        noErrors = false
      }
      if( billingProps.mobile && billingProps.mobile.trim().length > 0 && !numberValidator(billingProps.mobile.trim()) ) {
        ErrorMessage("billing-mobile-phone", "red", "billing-mobile-phone-error", `${translations.checkout.phone_detailed_error}`, "red")
        noErrors = false

        const element = document.getElementById('mobile-phone')
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }
      if( requireMobilePhone && billingProps.mobile && billingProps.mobile.trim().length === 0 ) {
        ErrorMessage("billing-mobile-phone", "red", "billing-mobile-phone-error", `${translations.checkout.general_error} ${translations.checkout.mobile_phone}`, "red")
        noErrors = false
      }
      if( !billingProps.address || billingProps.address.length === 0 ){
        ErrorMessage("billing-address", "red", "billing-address-error", `${translations.checkout.general_error} ${translations.checkout.address}`, "red")
        noErrors = false
      }
      if( !billingProps.city || billingProps.city.length === 0 ){
        ErrorMessage("billing-city", "red", "billing-city-error", `${translations.checkout.general_error} ${translations.checkout.city}`, "red")
        noErrors = false
      }
      if( !billingProps.country || billingProps.country.length === 0 ){
        ErrorMessage("billing-country", "red", "billing-country-error", `${translations.checkout.general_error} ${translations.checkout.country}`, "red")
        noErrors = false
      }
      if( !billingProps.postalCode || billingProps.postalCode.length === 0 ){
        ErrorMessage("billing-postal_code", "red", "billing-postal_code-error", `${translations.checkout.general_error} ${translations.checkout.postal_code}`, "red")
        noErrors = false
      }
      if( statesRequired && (!billingProps.area || billingProps.area.length === 0) ){
        ErrorMessage("billing-area", "red", "billing-area-error", `${translations.checkout.general_error} ${translations.checkout.state}`, "red")
        noErrors = false
      }
    }

    if (showInvoiceForm){
      if( !invoiceState.companyName || (invoiceState.companyName && invoiceState.companyName.length === 0) ) {
        ErrorMessage("company-name", "red", "company-name-error", `${translations.checkout.general_error} ${translations.invoices.companyName}`, "red")
        noErrors = false
      } else if(noErrors) {
        updateStep("billingProps", "companyName", invoiceState.companyName)
        updateStep("billingProps", "vatNumber", invoiceState.vatNumber)
        updateStep("billingProps", "additional_info", invoiceState.additionalInfo)

        let fd = new FormData()
        fd.append('invoice[company_name]', invoiceState.companyName)
        fd.append('invoice[vat_number]', invoiceState.vatNumber)
        fd.append('invoice[additional_info]', invoiceState.additionalInfo)
        fd.append('invoice[vat_validity]', invoiceState.vatValidity)

        Rails.ajax({
          type: 'POST',
          url: '/checkout/invoice',
          dataType: 'json',
          data: fd,
          success: res => {
            googleMeasurementId ? checkoutPaymentMethodAnalytics(selectedMethod) : checkoutPaymentMethodLegacyAnalytics(selectedMethod);
            proceedToNextStep()
          }
        })
      }
    } else {
      return noErrors ? proceedToNextStep() : null
    }
  }

  const paymentMethodItems = paymentMethods.map((method, index) => {
    return (
      <PaymentMethod
        key            = { index          }
        appProps       = { appProps }
        clearErrors    = { clearErrors }
        paymentMethod  = { method                      }
        selectedMethod = { selectedMethod              }
        updateSelectedPayment = { updateSelectedPayment }
        showCost = { shippingMethod.type !== "LocalPickup" }
        stripePublishableKey = { method.type === "StripePayment" ? stripePublishableKey : null }
        submitStripeCard = { submitStripeCard }
        stripePaymentIntentClientSecret = { stripePaymentIntentClientSecret }
        stripePaymentIntentActionType={ stripePaymentIntentActionType }
        stripeSetupIntentClientSecret={ stripeSetupIntentClientSecret }
        stripeSetupIntentActionType={ stripeSetupIntentActionType }
        setStripeError = { setStripeError }
        completeOrder = { completeOrder }
        currency = { currency }
        orderId = { orderId }
        type = { type }
        cardsPath={ cardsPath }
        setCurrentCardPath={ setCurrentCardPath }
        setLoading={ setLoading }
        stripeBillingDetails={ stripeBillingDetails }
      />
    )
  })

  return(
    <div id="billing-step" className="checkout-step payment card">
      <div className="step-title">
        { translations.checkout.payment_step }
      </div>

      <div id="payment-methods-list" className="shipping-methods">
        { paymentMethodItems }
      </div>

      { selectedMethod.installmentsPeriod && selectedMethod.type !== "JccPayment" &&
        <div className="installments-wrapper">
          <div>{ translations.checkout.use_payment_installments }</div>
          <div className="installments-select">
            <Select
              theme={ customReactSelectTheme }
              styles={ customReactSelectStyles }
              isSearchable={ false }
              options={Array.from(Array(parseInt(selectedMethod.installmentsPeriod)).keys()).map((i, index) => {
                return {value: i+1, label: `${i+1}`}
              })}
              value={{value: installments, label: `${installments}`}}
              defaultValue={{value: 1, label: `1`}}
              onChange={ (option)=> { updateInstallments(option.value) } }
            />
          </div>
        </div>
      }

      { showUseDeliveryAsBillingSwitch &&
        <div className="switch-wrapper">
          <div className="flex-box items-center">
            <label className="switch">
              <input
                type="checkbox"
                id='use-delivery-info-as-billing'
                name='use-delivery-info-as-billing'
                checked={ isDeliveryAddressUsedAsBilling }
                onChange={(event) => {
                  useDeliveryAddressAsBilling(event.target.checked)
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="switch-text">
            { translations.checkout.use_delivery_as_billing }
          </div>
        </div>
      }

      { canIssueInvoice &&
        <div className="field">
          <div>
            <div className="switch-wrapper">
              <div className="flex-box items-center">
                <label className="switch">
                  <input
                    type="checkbox"
                    defaultChecked={ showInvoiceForm || !!invoice }
                    onChange={ () => updateShowInvoiceForm(!showInvoiceForm) }
                    disabled={ companyCheckout }
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div  className="switch-text">
                { translations.invoices.createInvoice }
              </div>
            </div>
          </div>
        </div>
      }

      { showAddressForm &&
        <div className="fields">
          <div className="field-columns">
            <div className="field">
              <div className='flex-box items-center'>
                <label>
                  { translations.checkout.full_name }
                </label>
                <div style={{marginLeft: 5}}>*</div>
              </div>
              <input
                id="billing-full-name"
                type="text"
                value={ billingProps.fullName || '' }
                onChange={ (e)=> { updateStep("billingProps", "fullName", e.target.value) } }
                disabled={ companyCheckout }
              />
            </div>

            <div id="phone" className="field">
              <div className='flex-box items-center'>
                <label>
                  { translations.checkout.phone }
                </label>
                <div style={{marginLeft: 5}}>*</div>
              </div>
              <input
                id="billing-phone"
                type="text"
                value={ billingProps.phone || '' }
                onChange={ (e)=> { updateStep("billingProps", "phone", e.target.value)} }
                disabled={ companyCheckout }
              />
            </div>

            <div id="mobile-phone" className="field">
              <div className='flex-box items-center'>
                <label>
                  { translations.checkout.mobile_phone }
                </label>
                { requireMobilePhone && <div style={{marginLeft: 5}}>*</div> }
              </div>
              <input
                id="billing-mobile-phone"
                type="text"
                value={ billingProps.mobile || '' }
                onChange={ (e)=> { updateStep("billingProps", "mobile", e.target.value)} }
                disabled={ companyCheckout }
              />
            </div>
          </div>

          <div className="field-columns">
            <div className="field">
              <div className='flex-box items-center'>
                <label>
                { translations.checkout.address }
                </label>
                <div style={{marginLeft: 5}}>*</div>
              </div>
              <input
                id="billing-address"
                type="text"
                value={ billingProps.address || '' }
                onChange={ (e)=> { updateStep("billingProps", "address", e.target.value) } }
                disabled={ companyCheckout }
              />
            </div>

            <div className="field">
              <div className='flex-box items-center'>
                <label>
                  { translations.checkout.city }
                </label>
                <div style={{marginLeft: 5}}>*</div>
              </div>
              <input id="billing-city"
                type="text"
                value={ billingProps.city || '' }
                onChange={ (e)=> { updateStep("billingProps", "city", e.target.value) } }
                disabled={ companyCheckout }
              />
            </div>
          </div>

          <div className="field-columns">
            <div className="field">
              <div className='flex-box items-center'>
                <label>
                  { translations.checkout.postal_code  }
                </label>
                <div style={{marginLeft: 5}}>*</div>
              </div>
              <input id="billing-postal_code"
                type="text"
                value={ billingProps.postalCode || '' }
                onChange={ (e)=> { updateStep("billingProps", "postalCode", e.target.value) } }
                disabled={ companyCheckout }
              />
            </div>
          </div>

          <div className="field-columns">
            <div className="field">
              <div className='flex-box items-center'>
                <label>
                  { translations.checkout.country }
                </label>
                <div style={{marginLeft: 5}}>*</div>
              </div>
              <Select
                id="billing-country"
                theme={ customReactSelectTheme }
                styles={ customReactSelectStyles }
                isSearchable={ false }
                options={ availableCountries }
                value={ selectedCountry }
                onChange={ (option) => handleCountryChange(option) }
                onMenuOpen={ () => getAvailableCountries() }
                isDisabled={ companyCheckout }
              />
            </div>

            { statesRequired &&
              <div className="field">
                <div className='flex-box items-center'>
                  <label>
                  { translations.checkout.state }
                  </label>
                  <div style={{marginLeft: 5}}>*</div>
                </div>
                <Select
                  id="billing-area"
                  theme={ customReactSelectTheme }
                  styles={ customReactSelectStyles }
                  isSearchable={ false }
                  options={ availableStates }
                  value={ !selectedState && availableStates.length > 0 && deliveryProps.area && deliveryProps.area.length > 0 ? availableStates.filter(s => s.value === deliveryProps.area) : selectedState }
                  onChange={ (option)=> handleStateChange(option) }
                  onMenuOpen={ () => getAvailableStates(selectedCountry.value) }
                  isDisabled={ companyCheckout }
                />
              </div>
            }
          </div>
        </div>
      }

      {canIssueInvoice &&
        <Invoice
          invoiceState={ invoiceState }
          setInvoiceState={ setInvoiceState }
          translations={ translations }
          selectedCountry={ selectedCountry }
          selectedMethod={ selectedMethod }
          clearError={ clearError }
          showInvoiceForm={ showInvoiceForm }
          currentInvoice={ invoice }
          companyCheckout={ companyCheckout }
        />
      }

      { showContactless &&
        <div className="additional-options">
          <div className="contactless-switch switch-wrapper">
            <div className="switch-text">
              <strong>{ translations.checkout.contactless_delivery_label }</strong>
              <br/>
              { translations.checkout.contactless_delivery_text }
            </div>
            <div>
              <label className="switch">
                <input type="checkbox" name="contactlessEnabled" value="1" defaultChecked={ contactlessEnabled } onChange={(e) => {
                  updateContactless(e.target.checked)
                }}/>
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      }

      { !loading && paymentMethods.length > 0 &&
        <div className="field button-wrapper" style={{ marginTop: 30 }}>
          <a className="button" onClick={ () => handleClick() }>
            { translations.checkout.place_order_button }
          </a>
        </div>
      }
    </div>
  )

}
